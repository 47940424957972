<template>
  <div class="charging-global-wrapper">
    <ch-layout page>
<!--      <template v-slot:search>-->
<!--        <ch-search-->
<!--            :render-option="searchOption" :model="searchForm"-->
<!--            :props="{ labelWidth: '70px'}">-->
<!--        </ch-search>-->
<!--      </template>-->

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openWatch(scope.row)">查看</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            ref="page"
            :getListFunction="getTableList"
            type="old"
        ></ch-page>
      </template>
    </ch-layout>

  </div>
</template>
<script>
import router from "@/route/router";
export default {
  data() {
    return {
      searchForm:{
        type:null
      },
      tableList: [],
      isTableLoading: false,
      treeSelect: {
      },
      statusStrList:['','待处理','处理中','已撤诉','已处理','已完结(用户)','已完结(平台)'],
    };
  },
  computed: {
    // searchOption: function () {
    //   return [
    //     {type: 'select',  label: '配置类型：', prop: 'type', placeholder: '请选择类型',
    //       name: "label", value: "value", option: [
    //         {value: 1, label: '申诉'},
    //       ]},
    //     {type: 'button', button: [
    //         {text: '查询', onClick: this.searchHandle},
    //         {text: '重置', onClick: this.searchHandle},
    //       ]},
    //   ]
    // },
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index'},
        {column: 'text', label: '服务订单号', prop: 'serverNumber', showOverflowTooltip: true,width: '200px'},
        {column: 'text', label: '用户名字', prop: 'userName', showOverflowTooltip: true},
        {column: 'text', label: '手机号码', prop: 'telNumber', showOverflowTooltip: true},
        {column: 'text', label: '服务名称', prop: 'specName', showOverflowTooltip: true},
        {column: 'text', label: '支付金额(元)', prop: 'amount', showOverflowTooltip: true},
        {column: 'text', label: '服务人员', prop: 'serviceStaffName', showOverflowTooltip: true},
        {column: 'text', label: '创建时间', prop: 'createTime', showOverflowTooltip: true},
        {column: 'text', label: '状态', prop: '_status', showOverflowTooltip: true},
        {column: 'slot', label: '操作', slotName: 'handle', width: '150px', fixed: 'right' }
      ]
    }
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      if(this.$refs.page) {
        this.isTableLoading = true
        this.$refs.page.getServerList("/hm/hmGServiceOrderAppeal/list")
            .then((res) => {
              this.tableList = res.rows.map(item=>{
                return {
                  ...item,
                  _status: this.statusStrList[item.status]
                }
              })
            }).finally(() => this.isTableLoading = false)
      }
    },
    // searchHandle(){
    //   this.getTableList()
    // },
    openWatch(row) {
      router.push({ name: "userAppealDetail", query: { id: row.id } });
    },
  },
};
</script>
